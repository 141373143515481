/*===========================
    3.HERO  
===========================*/
.hero_background {
    background-image: url(../images/bg/hero-background.svg);
    overflow: visible;
    Background-size: cover;
    background-repeat: no-repeat;
    background-position-y: -100px;
    background-size: 1920px 879px;
}

.hero-area {
    padding-top: 200px;
    padding-bottom: 110px;
    overflow: hidden;
    position: relative;
    z-index: 10;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}



@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .hero-area .hero-content .title {
        font-size: 54px;
        line-height: 64px;
    }

    .hero_background {
        background-position-y: -150px;
        background-position-x: -354px;
    }
}




.hero-area .hero-shape-1 {
    position: absolute;
    left: 0;
    top: 250px;
    z-index: -1;
    animation: linear 20s animationFramesOne infinite;
    filter: drop-shadow(0px 0px 40px var(--primary-color));
}

.hero-area .hero-shape-2 {
    position: absolute;
    left: 80px;
    z-index: 11;
    top: 480px;
    animation: linear 25s animationFramesThree infinite;
    filter: drop-shadow(0px 0px 30px #ff9f43);
}

@media only screen and (max-width: 991px) {
    .hero-area .hero-shape-2 {
        left: 303px;
        top: 717px;
    }

}

.hero-area .hero-shape-3 {
    position: absolute;
    bottom: 211px;
    right: 626px;
    z-index: 11;
    animation: linear 25s animationFramesFive infinite;
    filter: drop-shadow(0px 0px 30px var(--primary-color));
}

@media only screen and(max-width: 991px) {
    .hero-area .hero-shape-3 {
        bottom: 94px;
        right: 329px;
    }
}

.hero-area.hero-8-area .home-8-shape-1 {
    position: absolute;
    left: 0;
    bottom: 100px;
    z-index: -1;
}

.hero-area.hero-8-area .home-8-shape-2 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}

.hero-area.hero-8-area .home-8-shape-3 {
    position: absolute;
    top: 400px;
    right: 290px;
    z-index: -1;
}

.hero-area.hero-8-area .home-8-shape-4 {
    position: absolute;
    bottom: 195px;
    left: 195px;
    z-index: -1;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .hero-area.hero-8-area .hero-content-8 .title {
        font-size: 56px;
        line-height: 66px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-area.hero-8-area .hero-content-8 .title {
        font-size: 42px;
        line-height: 66px;
    }
}

@media (max-width: 767px) {
    .hero-area.hero-8-area .hero-content-8 .title {
        font-size: 32px;
        line-height: 44px;
    }
}

.hero-thumb-6 .thumb {
    position: relative;
    z-index: 10;
}

.hero-thumb-6 .thumb .back-image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: -1;
}

.hero-content {
    text-align: start;
}

@media only screen and (max-width: 991px) {
    .hero__image {
        width: 350px
    }

    .hero-content p {
        margin: 0 auto;
    }
}

@media only screen and (max-width: 576px) {
    .hero__image {
        width: 300px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-content {
        margin-bottom: 50px;
        text-align: center;
    }

    .formpage-content {
        margin-bottom: 50px;
        text-align: right;
    }
}

@media (max-width: 767px) {
    .hero-content {
        margin-bottom: 50px;
        text-align: center;
    }

    .formpage-content {
        margin-bottom: 0px;
        text-align: right;
    }
}

.hero-content>span {
    color: var(--primary-color-dark);
    font-size: 14px;
    font-weight: 500;
}

.hero-content .title {
    color: #222;
    font-size: 60px;
    line-height: 70px;
    margin-top: 10px;
    margin-bottom: 22px;
    font-family: var(--font-hero);
    font-weight: bolder;
}

.formpage-content .title {
    color: #222;
    font-size: 60px;
    line-height: 70px;
    margin-top: 10px;
    margin-bottom: 22px;

}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .hero-content .title {
        font-size: 48px;
        line-height: 58px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-content .title {
        font-size: 48px;
        line-height: 80px;
        padding: 0 80px;
        text-align: center;
    }

    .formpage-content .title {
        font-size: 48px;
        line-height: 80px;
        padding: 0 80px;
        text-align: right;
    }
}

@media (max-width: 767px) {
    .hero-content .title {
        font-size: 32px;
        line-height: 45px;
        padding: 0 10px;
    }

    .formpage-content .title {
        margin-bottom: 10px;
    }

    .formpage-content .title {
        font-size: 32px;
        line-height: 45px;
        padding: 0;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .hero-content .title {
        font-size: 38px;
        line-height: 46px;
        font-weight: 700;
    }
}

.hero-content p {
    font-size: 22px;
    font-weight: 500;
    line-height: 35px;
}

.formpage-content p {
    font-size: 22px;
    font-weight: 500;
    line-height: 35px;
}

@media (max-width: 767px) {
    .hero-content p {
        font-size: 15px;
        line-height: 26px;
    }

    .formpage-content p {
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
    }

    .formpage-content .title {
        font-size: 30px;
        line-height: 46px;
        font-weight: 800;
    }

    .hero-content .title {
        font-size: 30px;
        line-height: 46px;
        font-weight: 800;
    }
}

.hero-content ul {
    margin: 41px 0 0;
    padding: 0;
    list-style-type: none;
}

.hero-content ul li {
    display: inline-block;
}

@media (max-width: 767px) {
    .hero-content ul li {
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .hero-content ul li {
        margin-bottom: 0px;
    }
}



.hero-content ul li a i {
    padding-right: 6px;
}

.hero-content ul li a:hover {
    background: transparent;
    color: #0e1133;
}

.hero-content ul li a.item-2 {
    background: transparent;
    color: #0e1133;
}

.hero-content ul li a.item-2:hover {
    background: #0e1133;
    color: #fff;
}

.hero-content.hero-content-4 {
    padding-right: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .hero-content.hero-content-4 {
        padding-right: 0;
    }
}

@media (max-width: 767px) {
    .hero-content.hero-content-4 {
        padding-right: 0;
    }
}


.hero-content.hero-content-4 .title {
    font-size: 70px;
    font-weight: 700;
    line-height: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .hero-content.hero-content-4 .title {
        font-size: 60px;
        line-height: 70px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-content.hero-content-4 .title {
        font-size: 50px;
        line-height: 60px;
    }
}


@media only screen and (min-width: 576px) and (max-width: 767px) {
    .hero-content.hero-content-4 .title {
        font-size: 40px;
        line-height: 50px;
    }


}


.hero-thumb {
    position: relative;
    z-index: 10;
    text-align: end;
}


.hero-thumb .thumb-2 {
    position: absolute;
    right: -30px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0px 30px 70px 0px rgba(68, 1, 15, 0.2);
}

.hero-thumb::before {
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    height: 400px;
    width: 400px;
    transform: translate(-64%, -50%);
    background: var(--primary-color);
    border-radius: 50%;
    z-index: -1;
}

@media only screen and (max-width: 1200px) {
    .hero-thumb::before {
        width: 380px;
        height: 380px;
    }

    .hero__image {
        width: 380px
    }
}

@media only screen and (max-width: 991px) {

    .hero-thumb {
        text-align: center;
    }

    .hero-thumb::before {
        width: 350px;
        height: 350px;
        transform: translate(-50%, -50%);

    }
}

@media (max-width: 767px) {
    .hero-content.hero-content-4 .title {
        font-size: 30px;
        line-height: 40px;
    }

    .hero__image {
        width: 300px
    }

    .hero-area {
        padding-top: 160px;
        padding-bottom: 40px;

    }

    .hero-thumb::before {
        background: var(--primary-background)
    }
}

@media only screen and (max-width: 576px) {
    .hero-thumb::before {
        width: 300px;
        height: 300px;
    }
}