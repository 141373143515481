/* ====================== [ Start Slider ] ====================== */



.slider {
  position: relative;
}

.slider.fixed-slider {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.slider .swiper-slide-active {
  z-index: 3;
}

.slider .parallax-slider {
  position: relative;
}

.slider .parallax-slider .swiper-slide {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 50px;
}

.slider .parallax-slider .swiper-slide .bg-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
}

.slider .parallax-slider .swiper-slide-active .caption h1 {
  -webkit-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.slider .parallax-slider .swiper-slide-active .caption h1 .char {
  -webkit-animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(60ms * var(--char-index));
  animation-delay: calc(60ms * var(--char-index));
}

.slider .parallax-slider .swiper-slide-active .caption .thin,
.slider .parallax-slider .swiper-slide-active .caption .btn-curve,
.slider .parallax-slider .swiper-slide-active .caption p {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.slider .parallax-slider .caption .thin {
  color: var(--primary-color);
  font-size: 30px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 8px;
  margin-bottom: 5px;
  opacity: 0;
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

.slider .parallax-slider .caption .thin span {
  color: var(--primary-color);
}

.slider .parallax-slider .caption h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 4px;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.5s, visibility 0.5s;
  -o-transition: opacity 0.5s, visibility 0.5s;
  transition: opacity 0.5s, visibility 0.5s;
}

.slider .parallax-slider .caption p {
  color: #eee;
  margin-top: 15px;
  opacity: 0;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

.slider .parallax-slider .caption .btn-curve {
  opacity: 0;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
  border: 2px solid;
  line-height: 2;
}

.slider .parallax-slider .caption .btn-curve span {
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
}

.slider .parallax-slider .caption .btn-curve:hover span {
  color: #0c0f16;
}

.slider .parallax-slider .caption.dig h1 {
  font-weight: 800;
  font-size: 80px;
  text-transform: uppercase;
}

.slider .parallax-slider .caption.dig h1 .tline {
  margin-left: 80px;
}

.slider .parallax-slider .caption.dig h1 .whitespace {
  width: 20px;
}

.slider .setone {
  position: absolute;
  bottom: 8%;
  right: 40px;
  z-index: 8;
}

.slider .setone .swiper-nav-ctrl {
  color: #ccc;
  font-size: 13px;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  margin: 5px 0;
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: static;
}

.slider .setone .swiper-nav-ctrl:after {
  display: none;
}

.slider .setone.setwo {
  position: static;
}

.slider .setone.setwo .swiper-nav-ctrl {
  border-radius: 50%;
}

.slider .setone.setwo .next-ctrl {
  position: absolute;
  top: 50%;
  right: 30px;
}

.slider .setone.setwo .prev-ctrl {
  position: absolute;
  top: 50%;
  left: 30px;
}

.slider .txt-botm {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  z-index: 8;
}

.slider .txt-botm .swiper-nav-ctrl {
  position: absolute;
  width: auto;
  height: auto;
  margin-top: auto;
  top: auto;
  bottom: 0;
}

.slider .txt-botm .swiper-nav-ctrl i {
  color: #ccc;
  font-size: 13px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  margin: 0;
  border-radius: 50%;
  display: inline-block;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.slider .txt-botm .swiper-nav-ctrl span {
  font-weight: 500;
  font-size: 12px;
  color: #fff;
}

.slider .txt-botm .swiper-nav-ctrl:after {
  display: none;
}

.slider .txt-botm .swiper-nav-ctrl.swiper-button-next {
  right: 40px;
}

.slider .txt-botm .swiper-nav-ctrl.swiper-button-next i {
  margin-left: 10px;
}

.slider .txt-botm .swiper-nav-ctrl.swiper-button-prev {
  left: 40px;
}

.slider .txt-botm .swiper-nav-ctrl.swiper-button-prev i {
  margin-right: 10px;
}

.slider .txt-botm .swiper-nav-ctrl:hover i {
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.slider .swiper-pagination-fraction.top {
  position: absolute;
  right: 15%;
  left: auto;
  bottom: auto;
  top: 20%;
  width: auto;
  color: #fff;
  font-size: 40px;
  font-weight: 500;
}

.slider .swiper-pagination-fraction.top .swiper-pagination-current {
  font-size: 60px;
  font-weight: 700;
  position: relative;
  color: transparent;
  -webkit-text-stroke: 0.5px #fff;
}

.slider .swiper-pagination-fraction.top .swiper-pagination-current:after {
  content: "/";
  color: #fff;
  position: relative;
  margin: 0 10px;
  font-size: 12px;
  font-weight: 100;
}

.slider .swiper-pagination-fraction.top.botm {
  top: auto;
  bottom: 40px;
  right: 40px;
}

.slider .swiper-pagination-fraction.steps {
  position: absolute;
  width: 100px;
  color: #fff;
  font-size: 15px;
  bottom: 40px;
  left: calc(50% - 50px);
  right: auto;
  top: auto;
  text-align: left;
}

.slider .swiper-pagination-fraction.steps:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 15%;
  width: 70%;
  height: 1px;
  background: rgba(255, 255, 255, 0.3);
}

.slider .swiper-pagination-fraction.steps .swiper-pagination-total {
  float: right;
}

.slider .social-icon {
  position: absolute;
  left: 40px;
  bottom: 40px;
  z-index: 8;
}

.slider .social-icon a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.05);
  color: #fff;
  font-size: 13px;
}

.cta__slider-wrapper {
  position: relative;
  width: 100%;
  height: 105vh;
  overflow: hidden;
}

.cta__slider-wrapper .block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
}

.cta__slider {
  min-height: 105vh;
}

.cta__slider .swiper-slide {
  overflow: hidden;
}

.cta__slider-arrows {
  display: block;
  position: absolute;
  bottom: 40px;
  right: 40px;
  z-index: 2;
}

.cta__slider-arrow {
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 55px;
  font-size: 13px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: block;
  cursor: pointer;
}

.cta__slider-arrow.cta__slider-arrow--previous {
  left: 0;
}

.cta__slider-arrow.cta__slider-arrow--next {
  left: 50%;
}

.cta__slider-item {
  position: relative;
}

.cta__slider-item .media-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.cta__slider-item .media-wrapper .bg-img {
  position: absolute;
  width: 70%;
  height: 100%;
  right: 0;
  top: 0;
}

.cta__slider-item .container {
  position: relative;
  z-index: 4;
}

.cta__slider-item .caption {
  position: relative;
}

.cta__slider-item .caption .top-corn,
.cta__slider-item .caption .bottom-corn {
  position: absolute;
  left: -60px;
  top: -60px;
  opacity: 0.3;
}

.cta__slider-item .caption .top-corn:after,
.cta__slider-item .caption .top-corn:before,
.cta__slider-item .caption .bottom-corn:after,
.cta__slider-item .caption .bottom-corn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: var(--primary-color);
}

.cta__slider-item .caption .top-corn:after,
.cta__slider-item .caption .bottom-corn:after {
  width: 30px;
  height: 1px;
}

.cta__slider-item .caption .top-corn:before,
.cta__slider-item .caption .bottom-corn:before {
  width: 1px;
  height: 30px;
}

.cta__slider-item .caption .bottom-corn {
  top: auto;
  bottom: -60px;
}

.cta__slider-item .caption .bottom-corn:before {
  top: auto;
  bottom: 0;
}

.cta__slider-item .caption h1 {
  font-size: 75px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.5s, visibility 0.5s;
  -o-transition: opacity 0.5s, visibility 0.5s;
  transition: opacity 0.5s, visibility 0.5s;
}

.cta__slider-item .caption .thin {
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 12px;
  margin-bottom: 5px;
  opacity: 0;
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

.cta__slider-item .caption p {
  color: #eee;
  font-weight: 300;
  font-size: 14px;
  opacity: 0;
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  opacity: 0;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  transition: all 0.4s;
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

.cta__slider-item .caption .btn-curve {
  opacity: 0;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.cta__slider-item.swiper-slide-active .caption h1 {
  -webkit-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
  opacity: 1;
  visibility: visible;
}

.cta__slider-item.swiper-slide-active .caption h1 .char {
  -webkit-animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(60ms * var(--char-index));
  animation-delay: calc(60ms * var(--char-index));
}

.cta__slider-item.swiper-slide-active .caption .btn-curve,
.cta__slider-item.swiper-slide-active .caption .thin,
.cta__slider-item.swiper-slide-active .caption p {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.freelancer {
  min-height: 100vh;
  background-position: right top;
}

.freelancer .cont {
  margin-top: 50px;
}

.freelancer .cont h6 {
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-bottom: 5px;
}

.freelancer .cont h1 {
  font-size: 100px;
  font-weight: 700;
  margin-bottom: 5px;
}

.freelancer .cont h4 {
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.freelancer .cont .social-icon {
  margin-top: 40px;
}

.freelancer .cont .social-icon a {
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.05);
  margin-right: 5px;
}

.freelancer .img {
  position: absolute;
  right: 15%;
  bottom: 0;
  width: 400px;
  z-index: 2;
}

.slider-bus {
  min-height: 100vh;
  position: relative;
}

.slider-bus .swiper-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slider-bus .setone {
  position: absolute;
  bottom: 8%;
  right: 40px;
  z-index: 8;
}

.slider-bus .setone .swiper-nav-ctrl {
  color: #ccc;
  font-size: 13px;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  margin: 5px 0;
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: static;
}

.slider-bus .setone .swiper-nav-ctrl:after {
  display: none;
}

.slider-bus .setone.setwo {
  position: static;
}

.slider-bus .setone.setwo .swiper-nav-ctrl {
  border-radius: 50%;
}

.slider-bus .setone.setwo .next-ctrl {
  position: absolute;
  top: 50%;
  right: 30px;
}

.slider-bus .setone.setwo .prev-ctrl {
  position: absolute;
  top: 50%;
  left: 30px;
}

.slider-bus .swiper-pagination-fraction {
  position: absolute;
  right: auto;
  left: 60px;
  bottom: 30px;
  width: auto;
  color: #fff;
  font-size: 40px;
  font-weight: 500;
}

.slider-bus .swiper-pagination-fraction .swiper-pagination-current {
  font-size: 60px;
  font-weight: 700;
  position: relative;
  color: transparent;
  -webkit-text-stroke: 0.5px #fff;
}

.slider-bus .swiper-pagination-fraction .swiper-pagination-current:after {
  content: "/";
  color: #fff;
  position: relative;
  margin: 0 10px;
  font-size: 12px;
  font-weight: 100;
}

.slider-bus .swiper-slide-active .caption h1 {
  -webkit-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.slider-bus .swiper-slide-active .caption h1 .char {
  -webkit-animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(60ms * var(--char-index));
  animation-delay: calc(60ms * var(--char-index));
}

.slider-bus .swiper-slide-active .caption .thin,
.slider-bus .swiper-slide-active .caption .simple-btn,
.slider-bus .swiper-slide-active .caption .btn-curve,
.slider-bus .swiper-slide-active .caption p {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.slider-bus .caption .thin {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 8px;
  margin-bottom: 5px;
  opacity: 0;
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

.slider-bus .caption h1 {
  font-size: 90px;
  font-weight: 800;
  letter-spacing: 4px;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.5s, visibility 0.5s;
  -o-transition: opacity 0.5s, visibility 0.5s;
  transition: opacity 0.5s, visibility 0.5s;
}

.slider-bus .caption p {
  color: #eee;
  opacity: 0;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

.slider-bus .caption .simple-btn,
.slider-bus .caption .btn-curve {
  opacity: 0;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.slider-bus .ontop {
  position: relative;
  z-index: 5;
}

.curve-bg {
  position: absolute;
  top: 0;
  left: -10%;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.pages-header {
  height: 75vh;
}

.pages-header .cont h1 {
  font-size: 75px;
  margin-bottom: 20px;
}

.pages-header .cont .path {
  color: #fff;
  font-size: 17px;
}

.pages-header .cont .path .active {
  color: var(--primary-color);
}

.pages-header .cont .path span {
  margin: 0 20px;
  font-size: 10px;
}

.page-header .cont {
  padding: 220px 0 50px;
}

.page-header .cont h6 {
  color: #ccc;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.page-header .cont h4 {
  line-height: 1.6;
}

.page-header .cont h4 .stroke {
  font-weight: 900;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.7);
  color: transparent;
}

.page-header .img-wrapper {
  position: relative;
  min-height: 70vh;
}

.page-header .img-wrapper .title {
  position: absolute;
  top: 0;
  right: 0px;
  width: 100%;
  text-align: right;
  -webkit-transform: translateY(-55%);
  -ms-transform: translateY(-55%);
  transform: translateY(-55%);
  z-index: 3;
}

.page-header .img-wrapper .title h3 {
  font-size: 100px;
  font-weight: 900;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
}

.page-header .img-wrapper .map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page-header.proj-det.bg-img {
  min-height: 85vh;
  padding-bottom: 100px;
}

.page-header.proj-det.bg-img .item h6 {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.page-header.proj-det.bg-img .item p {
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
}

.page-header.proj-det.bg-img .item p a {
  margin: 0 7px;
}

.page-header.proj-det.bg-img .item p a:first-of-type {
  margin-left: 0;
}

.page-header.proj-det.bg-img .item p a:last-of-type {
  margin-right: 0;
}

.page-header.proj-det .img-wrapper {
  min-height: 80vh;
}

.works-header {
  min-height: 80vh;
  text-align: start;
}

/* .works-header.hfixd {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
} */

.works-header .capt h4 {
  font-weight: 200;
  font-size: 24px;
  line-height: 1.8;
}

.works-header .capt h2 {
  font-size: 60px;
  text-transform: uppercase;
}

.works-header .capt h2 span {
  display: block;
  font-size: 20px;
  font-weight: 300;
  text-transform: capitalize;
  color: var(--primary-color);
}

.full-width {
  width: 100% !important;
}

.contact_color {
  color: #edca9213;
}

@media (max-width: 768px) {
  .full-width {
    display: none;
  }
}

.works-header .capt .bactxt {
  font-size: 20vw;
  font-weight: bold;
  color: transparent;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.1);
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  text-align: center;
}

.static {
  position: static;
}

.main-content {
  background: #0c0f16;
  position: relative;
  z-index: 3;
}

/* ====================== [ End Slider ] ====================== */